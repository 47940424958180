import { BrowserRouter, Switch, Route, Links } from "react-router-dom";

import Header from "./components/Header";
import Footer from "./components/Footer";
import Property from "./components/Property";
import SecretPage from "./components/SecretPage";
import BusinessPage from "./components/BusinessPage";
import BusinessPage2 from "./components/BusinessPage2";
import Top from "./pages/Top";

import Umusa from "./pages/Umusa";
import Umusa2 from "./pages/Umusa2";
import Hamamoto from "./pages/Hamamoto";
import Ryuguzyo from "./pages/Ryuguzyo";
import Yamakawags from "./pages/Yamakawags";
import Nanmamui from "./pages/Nanmamui";
import Yaga from "./pages/Yaga";
import Untenbaru from "./pages/Untenbaru";
import "bootstrap/dist/css/bootstrap.min.css";

function App() {
  return (
    <>
      {/* V6 */}
      {/* <BrowserRouter>
        <div className="App">
          <Header />
          <Routes>
            <Route path="/" element={<Top />} />
            <Route path="/Property" element={<Property />} />
            <Route path="/secretpage" element={<SecretPage />} />
            <Route path="/businesspage" element={<BusinessPage />} />
            <Route path="/businesspage2" element={<BusinessPage2 />} /> */}

      {/* 物件詳細ページ */}
      {/* </Routes>
        </div>
        <Footer />
      </BrowserRouter> */}
      {/* V6 */}
      {/* V5 */}
      <div className="App">
        <Header />
        <BrowserRouter>
          <Switch>
            <Route exact path="/">
              <Top />
            </Route>
            <Route path="/property">
              <Property />
            </Route>
            <Route path="/secretpage">
              <SecretPage />
            </Route>
            <Route path="/businesspage">
              <BusinessPage />
            </Route>
            <Route path="/businesspage2">
              <BusinessPage2 />
            </Route>
            <Route path="/umusa">
              <Umusa />
            </Route>
            <Route path="/umusa2">
              <Umusa2 />
            </Route>
            <Route path="/hamamoto">
              <Hamamoto />
            </Route>
            <Route path="/ryuguzyo">
              <Ryuguzyo />
            </Route>
            <Route path="/yamakawags">
              <Yamakawags />
            </Route>
            <Route path="/nanmamui">
              <Nanmamui />
            </Route>
            <Route path="/yaga">
              <Yaga />
            </Route>
            <Route path="/Untenbaru">
              <Untenbaru />
            </Route>
          </Switch>
        </BrowserRouter>
        <Footer />
      </div>
    </>
  );
}

export default App;
