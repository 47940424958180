import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";

// import required modules
// import { Pagination } from "swiper/modules";
import { Autoplay, Pagination, Navigation } from "swiper/modules";

import "./../css/Kanagawa.css";
// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";

import photo1 from "./../images/koajiro1.jpg";
import photo2 from "./../images/sajima2.jpg";
import photo3 from "./../images/koajiro2.jpg";

const Kanagawa = () => {
  return (
    <>
      <section id="about" class="scrollspy-section padding-xlarge ">
        <div class="container">
          <div class="row">
            <div class="col-md-12">
              <div class="section-header">
                <div class="title">
                  <span>私たちについて</span>
                </div>
                <h2 class="section-title">About Us</h2>
              </div>
            </div>
          </div>

          <div class="col-md-12 description text-lead text-left">
            <h4>長年、ウォーターフロントとともに。</h4>
            <p className="text">
              ベルツコーポレーションでは、横浜・湘南・鎌倉・葉山・逗子を中心とした神奈川県のベイエリアで
              <br />
              船舶の停泊できる開発分譲や特に眺望の優れた不動産などを長らく取り扱ってまいりました。
              <br />
              そのスキルや経験をバックボーンとして四年前に沖縄にも営業所を構え、
              <br />
              同県でも特に秀逸なビーチフロント物件やオーシャンビュー物件、眺望物件、船舶の停泊可能な物件など
              <br />
              スーパーラグジュアリーな不動産を数多く取り扱っております。
            </p>
          </div>
          {/* スワイパー */}
          <Swiper
            slidesPerView={2}
            spaceBetween={30}
            loop={true}
            autoplay={{
              delay: 3000,
              disableOnInteraction: false,
            }}
            breakpoints={{
              // when window width is >= 320px
              320: {
                slidesPerView: 1,
                spaceBetween: 20,
              },
              // when window width is >= 480px
              480: {
                slidesPerView: 1,
                spaceBetween: 30,
              },
              // when window width is >= 640px
              640: {
                slidesPerView: 3,
                spaceBetween: 40,
              },
            }}
            pagination={{
              clickable: true,
            }}
            // modules={[Pagination]}
            modules={[Autoplay, Pagination, Navigation]}
            className="mySwiper"
          >
            <SwiperSlide className="swiper-sliede">
              <img src={photo1} alt="banner" className="img-slide2" />
            </SwiperSlide>
            <SwiperSlide className="swiper-sliede">
              <img src={photo2} alt="banner" className="img-slide2" />
            </SwiperSlide>
            <SwiperSlide className="swiper-sliede">
              <img src={photo3} alt="banner" className="img-slide2" />
            </SwiperSlide>
          </Swiper>
        </div>
      </section>
    </>
  );
};

export default Kanagawa;
