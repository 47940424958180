import { useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Thumbs } from "swiper/modules";
import { FaYenSign } from "react-icons/fa";

import "./../css/Property.css";
import "swiper/css";
import "swiper/css/navigation";

import "swiper/css/navigation";
import "swiper/css";
import "./../css/Pages.css";

import umusa1 from "./../images/umusa2-1.jpg";
import umusa2 from "./../images/umusa2-6.jpg";
import umusa3 from "./../images/umusa2-5.jpg";
import umusa4 from "./../images/umusa2-4.jpg";
import umusa5 from "./../images/umusa2-7.jpg";
import umusa6 from "./../images/umusa2-2.jpg";
import umusa7 from "./../images/umusa2-3.jpg";
import video from "./../images/umusa2_movie.mp4";

const Umusa2 = () => {
  const [thumbs, setThumbs] = useState(null);
  const images = [umusa1, umusa2, umusa3, umusa4, umusa5, umusa6, umusa7];

  return (
    <>
      <section id="on-sale" class="scrollspy-section padding-large">
        <div class="container">
          {/* <div class="row">
            <div class="col">
              <div class="section-header">
                <div class="title">
                  <span>販売中物件</span>
                </div>
                <h2 class="section-title">On sale</h2>
              </div>
            </div>
          </div> */}
          {/* 物件情報ここから1 */}
          <div class="row">
            <div class="col-md-12">
              <div class="post-grid">
                <div class="row">
                  {/* <div class="col-md-3"> */}
                  <article class="post-item">
                    {/* <figure> */}
                    {/* <a href="#" class="image-hvr-effect"> */}
                    {/* <img src={umusa} alt="post" class="post-image" /> */}
                    {/* </a> */}
                    {/* </figure> */}
                    <div class="post-content">
                      <div class="meta-date">
                        <div className="chips">販売中土地</div>
                      </div>
                      <h3 class="post-title">
                        {/* <a href="#"> */}
                        御成約済の宇茂佐と同じビーチに面したスーパーラグジュアリーなビーチフロント物件　分割相談可能
                        {/* </a> */}
                      </h3>

                      {/* スワイパー */}
                      {/* <Swiper
                        navigation={true}
                        modules={[Navigation]}
                        className="mySwiper"
                      >
                        <SwiperSlide className="swiper-sliede">
                          <img
                            src={umusa1}
                            alt="banner"
                            className="img-slide"
                          />
                        </SwiperSlide>
                        <SwiperSlide className="swiper-sliede">
                          <img
                            src={umusa2}
                            alt="banner"
                            className="img-slide"
                          />
                        </SwiperSlide>
                      </Swiper> */}

                      {/* Swiper2 */}
                      <Swiper
                        className="pages-swiper"
                        loop={true}
                        modules={[Navigation, Thumbs]}
                        thumbs={{
                          swiper: thumbs && !thumbs.destroyed ? thumbs : null,
                        }}
                        navigation
                      >
                        {images.map((src, idx) => (
                          <SwiperSlide key={idx}>
                            <img src={src} className="img-slide3" />
                          </SwiperSlide>
                        ))}
                      </Swiper>
                      <Swiper
                        className="pages-swiper Swiper1"
                        slidesPerView={7}
                        onSwiper={setThumbs}
                      >
                        {images.map((src, idx) => (
                          <SwiperSlide key={idx}>
                            <img src={src} />
                          </SwiperSlide>
                        ))}
                      </Swiper>

                      {/* <div class="meta-date">
                        <div className="chips">販売中土地</div>
                      </div> */}
                      <div class="tag-box">
                        <div class="box">
                          <div class="centered-text">別荘</div>
                        </div>
                        <div class="box">
                          <div class="centered-text">ヴィラ</div>
                        </div>
                        <div class="box">
                          <div class="centered-text">オーシャンビュー</div>
                        </div>
                        <div class="box">
                          <div class="centered-text">オーシャンフロント</div>
                        </div>
                      </div>

                      <video
                        src={video}
                        muted
                        autoPlay
                        playsInline
                        loop
                        className="showcase-video"
                      ></video>

                      <div className="comment-box">
                        <p className="">
                          大規模テーマパーク「JANGLIA」 車で約18分（9.6KM)
                          <br />
                          日ハムキャンプ地 タピックスタジアム 車で約8分（約3KM）
                          <br />
                          名護の繁華街みどり街 車で約7分（約3.2KM）
                          <br />
                          21世紀の森ビーチ 車で約8分（約3KM）
                          <br />
                          ファミリーマート名護宇茂佐店 徒歩約3分（約240M）
                          <br />
                          中北部で有名なレストラン「ふりっぱー」徒歩約7分（500M）
                          <br />
                          屋部小学校 徒歩9分（約700M）
                          <br />
                          名護市立屋部中学校 徒歩約13分（約1000M）
                          <br />
                          <br />
                          庭からそのまま海へアプローチ！ジェットスキーも釣りもご自分の”庭”からできます！
                          名護の繁華街までも近く、周辺の施設も充実しております。
                        </p>
                      </div>

                      <div className="price-box">
                        <div className="price-title">
                          <p>価格</p>
                        </div>
                        <div className="box-text">
                          <p>金額についてはご相談ください。</p>
                        </div>
                        <div />
                      </div>

                      <div className="all-box">
                        <div className="one-box">
                          <div className="box-title">
                            <p>所在地</p>
                          </div>
                          <div className="box-text">
                            <p>名護市字宇茂佐西兼久原</p>
                          </div>
                        </div>
                        <div className="one-box">
                          <div className="box-title">
                            <p>交通</p>
                          </div>
                          <div className="box-text">
                            <p>那覇空港から車で約1時間15分</p>
                          </div>
                        </div>
                        <div className="one-box">
                          <div className="box-title">
                            <p>土地権利</p>
                          </div>
                          <div className="box-text">
                            <p>所有権</p>
                          </div>
                        </div>
                        <div className="one-box">
                          <div className="box-title">
                            <p>土地面積</p>
                          </div>
                          <div className="box-text">
                            <p>
                              公簿：530㎡ （約160.32坪)
                              実測：531.02㎡（約160.63坪）
                            </p>
                          </div>
                        </div>
                        <div className="one-box">
                          <div className="box-title">
                            <p>地目</p>
                          </div>
                          <div className="box-text">
                            <p>畑</p>
                          </div>
                        </div>
                        <div className="one-box">
                          <div className="box-title">
                            <p>用途地域</p>
                          </div>
                          <div className="box-text">
                            <p>都市計画区域内 市街化区域　第1種住居地域</p>
                          </div>
                        </div>
                        <div className="one-box">
                          <div className="box-title">
                            <p>建蔽率</p>
                          </div>
                          <div className="box-text">
                            <p>60%（角地緩和：70％）</p>
                          </div>
                        </div>
                        <div className="one-box">
                          <div className="box-title">
                            <p>容積率</p>
                          </div>
                          <div className="box-text">
                            <p>200% （前面道路幅員の制限により160%）</p>
                          </div>
                        </div>
                        <div className="one-box">
                          <div className="box-title">
                            <p>接道</p>
                          </div>
                          <div className="box-text">
                            <p>
                              北側 公道幅員：約3.4m～3.6m 西側 公道幅員：未調査
                              南西側 里道幅員：不明
                              ※建築基準法上の道路ではありません。
                            </p>
                          </div>
                        </div>
                        <div className="one-box">
                          <div className="box-title">
                            <p>設備</p>
                          </div>
                          <div className="box-text">
                            <p>沖縄電力、個別プロパン、公営水道、公共下水</p>
                          </div>
                        </div>
                        <div className="one-box">
                          <div className="box-title">
                            <p>現況</p>
                          </div>
                          <div className="box-text">
                            <p>更地</p>
                          </div>
                        </div>
                        <div className="one-box">
                          <div className="box-title">
                            <p>取引態様</p>
                          </div>
                          <div className="box-text">
                            <p>専属専任媒介</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </article>

                  <div class="iframe-box">
                    <iframe
                      src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d445.95548767538406!2d127.955837!3d26.5958!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x34e4ff12e9f0cdcd%3A0xaca8e040321ca5f8!2z44CSOTA1LTAwMDYg5rKW57iE55yM5ZCN6K235biC5a6H6IyC5L2Q77yT77yQ77yY!5e0!3m2!1sja!2sjp!4v1736844187208!5m2!1sja!2sjp"
                      frameborder="0"
                      allowfullscreen
                    ></iframe>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Umusa2;
